<template>
    <b-container fluid id="privacy">
      <div>
        <p class="normal0" style="margin-bottom:53pt; text-align:right; line-height:160%; font-size:13.5pt"> <span
          style="font-weight:bold">Last updated on:</span> <span> 4th September 2023</span> </p>
        <h1
          style="margin-top:0pt; margin-bottom:37pt; text-align:center; page-break-inside:auto; page-break-after:auto; line-height:120%; font-size:28.5pt">
          <a name="_ipttt7fapri7"></a> <span style="background-color:#f7f7f7">Privacy Policy</span> </h1>
        <p class="normal0" style="margin-bottom:41pt; text-align:center; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This Privacy Policy statement is made by the House of Banquets consisting
            of all the entities (collectively, "House of Banquets", "we", "us" or "our").</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:49pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_onmbxggks4qt"></a> <span style="color:#111111; background-color:#f7f7f7">House of Banquets’s Privacy
            Commitment</span> </h3>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">House of Banquets has worried about customer and user privacy for years,
            long before it became fashionable, politically correct, or legally binding to take such a position. We ask
            for only the least amount of information necessary, gathering only what we believe is essential for doing
            business, or for the specific transaction at hand. We let customers know the information we have on them and
            allow them to opt out of specific engagements. But, by far, our biggest commitment is that we do not make a
            single dollar from advertising revenue—never have, never will—even from the free editions of our products.
            This means we avoid the fundamental conflict of interest between gathering customer information and fueling
            advertising revenue, and the unavoidable compromises in customer privacy that it brings.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">The goal of this policy is to make explicit the information we gather, how
            we will use it, and how we will not. This policy is unfortunately longer than we would like, but we must
            unambiguously address all the relevant cases. We will try and keep the language simple and direct as much as
            possible.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:49pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_lowvzb5ckbxe"></a> <span style="color:#111111; background-color:#f7f7f7">Scope of this Privacy
            Policy</span> </h3>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This Privacy Policy applies to all House of Banquets websites that link to
            it. It also applies to the products and services provided by House of Banquets through these websites and
            our mobile applications. This Privacy Policy does not apply to any of our websites, products or services
            that have a separate privacy policy.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This Privacy Policy is divided into three parts:</span> </p>
        <h5
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:13pt">
          <a name="_y275rmfdmmto"></a> <span style="color:#111111; background-color:#f7f7f7">Part I – Information House of
            Banquets collects and controls</span> </h5>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This part deals with how House of Banquets collects and uses information
            about website visitors, potential customers, users of House of Banquets's products and services, and others
            who contact House of Banquets through forms or email addresses published on or linked to our
            websites.</span> </p>
        <h5
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:13pt">
          <a name="_v7gts6buax1v"></a> <span style="color:#111111; background-color:#f7f7f7">Part II – Information that
            House of Banquets processes on your behalf</span> </h5>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This part deals with how House of Banquets handles data that you entrust to
            House of Banquets when you use our products and services, or when you share any personal or confidential
            information with us while requesting customer support.</span> </p>
        <h5
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:13pt">
          <a name="_i9g734g46v7"></a> <span style="color:#111111; background-color:#f7f7f7">Part III – General</span>
        </h5>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">This part deals with topics that are relevant to both Parts I and II, and
            other general topics such as House of Banquets's security commitments and how we will inform you when we
            change this Privacy Policy.</span> </p>
        <h2
          style="margin-top:0pt; margin-bottom:61pt; text-align:center; page-break-inside:auto; page-break-after:auto; line-height:125%; font-size:22.5pt">
          <a name="_5iscl53rvm3v"></a> <span style="background-color:#ffffff">Part I – Information House of Banquets
            collects and controls</span> </h2>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_z5xev0gmn4fe"></a> <span style="color:#111111; background-color:#ffffff">What information House of
            Banquets collects</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We collect information about you only if we need the information for some
            legitimate purpose. House of Banquets will have information about you only if (a) you have provided the
            information yourself, (b) House of Banquets has automatically collected the information, or (c) House of
            Banquets has obtained the information from a third party. Below we describe the various scenarios that fall
            under each of those three categories and the information collected in each one.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#ffffff">Information that you provide us</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-weight:bold; background-color:#ffffff">i. </span> <span
          style="font-weight:bold; font-style:italic; background-color:#ffffff">Account signup </span> <span
          style="background-color:#ffffff">: When you sign up for an account to access one or more of our services, we
            ask for information like your name, contact number, email address, company name and country to complete the
            account signup process. You may also provide us with more information such as your photo, time zone and
            language, but we don’t require that information to sign up for an account.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-weight:bold; background-color:#ffffff">ii. </span> <span
          style="font-weight:bold; font-style:italic; background-color:#ffffff">Event registrations and other form
            submissions: </span> <span style="background-color:#ffffff">We record information that you submit when you
            (i) register for any event, including webinars or seminars, (ii) subscribe to our newsletter or any other
            mailing list, (iii) submit a form in order to download any product, whitepaper, or other materials, (iv)
            participate in contests or respond to surveys, or (v) submit a form to request customer support, get a quote
            or to contact House of Banquets for any other purpose.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-weight:bold; background-color:#ffffff">iii. </span> <span
          style="font-weight:bold; font-style:italic; background-color:#ffffff">Testimonials </span> <span
          style="background-color:#ffffff">: When you authorize us to post testimonials about our products and
            services on websites, we may include your name and other personal information in the testimonial. You will
            be given an opportunity to review and approve the testimonial before we post it. If you wish to update or
            delete your testimonial, you can contact us at info@houseofbanquets.com</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-weight:bold; background-color:#ffffff">iv. </span> <span
          style="font-weight:bold; font-style:italic; background-color:#ffffff">Interactions with House of Banquets
        </span> <span style="background-color:#ffffff">: We may record, analyze and use your interactions with us,
            including email, telephone, and chat conversations with our sales and customer support professionals, for
            improving our interactions with you and other customers.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#ffffff">Information that we collect automatically</span>
        </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">i. </span> <span
          style="font-style:italic; background-color:#ffffff">Information from browsers, devices and servers </span>
          <span style="background-color:#ffffff">: When you visit our websites, we collect information that web browsers,
            mobile devices and servers make available, such as the internet protocol address, browser type, language
            preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer
            and mobile network information. We include these in our log files to understand more about visitors to our
            websites.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">ii. </span> <span
          style="font-style:italic; background-color:#ffffff">Information from application logs and mobile analytics
        </span> <span style="background-color:#ffffff">: We collect information about your use of our products, services
            and mobile applications from application logs and in-house usage analytics tools, and use it to understand
            how your use and needs can improve our products. This information includes clicks, scrolls, features
            accessed, access time and frequency, errors generated, performance data, storage utilized, user settings and
            configurations, and devices used to access and their locations.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#ffffff">Information that we collect from third
            parties</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">i. </span> <span
          style="font-style:italic; background-color:#ffffff">Referrals </span> <span
          style="background-color:#ffffff">: If someone has referred any of our products or services to you through
            any of our referral programs, that person may have provided us your name, email address and other personal
            information. You may contact us at info@houseofbanquets.com to request that we remove your information from
            our database. If you provide us information about another person, or if another person gives us your
            information, we will only use that information for the specific reason for which it was provided to
            us.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">ii. </span> <span
          style="font-style:italic; background-color:#ffffff">Information from our reselling partners and service
            providers </span> <span style="background-color:#ffffff">: If you contact any of our reselling partners, or
            otherwise express interest in any of our products or services to them, the reselling partner may pass your
            name, email address, company name and other information to House of Banquets. If you register for or attend
            an event that is sponsored by House of Banquets, the event organizer may share your information with us.
            House of Banquets may also receive information about you from review sites if you comment on any review of
            our products and services, and from other third-party service providers that we engage for marketing our
            products and services.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">iii. </span> <span
          style="font-style:italic; background-color:#ffffff">Information from social media sites and other publicly
            available sources </span> <span style="background-color:#ffffff">: When you provide feedback or reviews
            about our products, interact, or engage with us on marketplaces, review sites or social media sites such as
            Facebook, Twitter, LinkedIn and Instagram through posts, comments, questions and other interactions, we may
            collect such publicly available information, including profile information, to allow us to connect with you,
            improve our products, better understand user reactions and issues, or to reproduce and publish your feedback
            on our websites. We must tell you that once collected, this information may remain with us even if you
            delete it from these sites. House of Banquets may also add and update information about you, from other
            publicly available sources.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_62ttss111ifd"></a> <span style="color:#111111; background-color:#ffffff">Purposes for using
            information</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">In addition to the purposes mentioned above, we may use your information
            for the following purposes:</span> </p>
        <p class="normal0" style="margin-top:15pt; margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt">
        <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To communicate with you (such as through email) about products
            that you have downloaded and services that you have signed up for, changes to this Privacy Policy, changes
            to the Terms of Service, or important notices;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To keep you posted on new products and services, upcoming events,
            offers, promotions and other information that we think will be of interest to you;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To ask you to participate in surveys, or to solicit feedback on
            our products and services;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To set up and maintain your account, and to do all other things
            required for providing our services, such as enabling collaboration, providing website and mail hosting, and
            backing up and restoring your data;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To understand how users use our products and services, to monitor
            and prevent problems, and to improve our products and services;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To provide customer support, and to analyze and improve our
            interactions with customers;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To detect and prevent fraudulent transactions and other illegal
            activities, to report spam, and to protect the rights and interests of House of Banquets, House of
            Banquets’s users, third parties and the public;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To update, expand and analyze our records, identify new
            customers, and provide products and services that may be of interest to you;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To analyze trends, administer our websites, and track visitor
            navigations on our websites to understand what visitors are looking for and to better help them;</span> </p>
        <p class="normal0" style="margin-left:36pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span></span> </p>
        <p class="normal0"
           style="margin-left:36pt; margin-bottom:83pt; text-indent:-18pt; line-height:115%; font-size:13.5pt"> <span
          style="width:18pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span> <span style="background-color:#ffffff">To monitor and improve marketing campaigns and make suggestions
            relevant to the user.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_rklz614wm7l0"></a> <span style="color:#111111; background-color:#ffffff">Legal bases for collecting
            and using information</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Legal processing bases applicable to House of Banquets
        </span> <span style="background-color:#ffffff">: Our legal basis for information collection and use depends on
            the personal information concerned and the context in which we collect it. Most of our information
            collection and processing activities are typically based on (i) contractual necessity, (ii) one or more
            legitimate interests of House of Banquets or a third party that are not overridden by your data protection
            interests, or (iii) your consent. Sometimes, we may be legally required to collect your information, or may
            need your personal information to protect your vital interests or those of another person.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Withdrawal of consent </span> <span
          style="background-color:#ffffff">: Where we rely on your consent as the legal basis, you have the right to
            withdraw your consent at any time, but this will not affect any processing that has already taken
            place.</span> </p>
        <p class="normal0" style="margin-bottom:53pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Legitimate interests notice </span> <span
          style="background-color:#ffffff">: Where we rely on legitimate interests as the legal basis and those
            legitimate interests are not specified above, we will clearly explain to you what those legitimate interests
            are at the time that we collect your information.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_5ngl0wsn9hd2"></a> <span style="color:#111111; background-color:#ffffff">Your choice in information
            use</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Opt out of non-essential electronic communications
        </span> <span style="background-color:#ffffff">: You may opt out of receiving newsletters and other
            non-essential messages by using the ‘unsubscribe’ function included in all such messages. However, you will
            continue to receive essential notices and emails such as account notification emails (password change,
            renewal reminders, etc.), security incident alerts, security and privacy update notifications.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_9ivl0f1v8orl"></a> <span style="color:#111111; background-color:#ffffff">Who we share your information
            with</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We do not sell any personal information. We share your information only in
            the ways that are described in this Privacy Policy, and only with parties who adopt appropriate
            confidentiality and security measures.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Employees and independent contractors </span> <span
          style="background-color:#ffffff">: Employees and independent contractors of relevant House of Banquets web
            and mobile applications have access to the information covered in Part I on a need-to-know basis. We require
            all employees and independent contractors of House of Banquets web and mobile applications to follow this
            Privacy Policy for personal information that we share with them.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Third-party service providers </span> <span
          style="background-color:#ffffff">: We may need to share your personal information and aggregated or
            de-identified information with third-party service providers that we engage, such as marketing and
            advertising partners, event organizers, web analytics providers and payment processors. These service
            providers are authorized to use your personal information only as necessary to provide these services to
            us.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Other cases </span> <span
          style="background-color:#ffffff">: Other scenarios in which we may share the same information covered under
            Parts I and II are described in Part III.</span> </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_2re0h3v0oo4v"></a> <span style="color:#111111; background-color:#ffffff">Your rights with respect to
            information we hold about you as a controller</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to access </span> <span
          style="background-color:#ffffff">: You have the right to access (and obtain a copy of, if required) the
            categories of personal information that we hold about you, including the information's source, purpose and
            period of processing, and the persons to whom the information is shared.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to rectification </span> <span
          style="background-color:#ffffff">: You have the right to update the information we hold about you or to
            rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add
            supplemental information about you in our database.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to erasure </span> <span
          style="background-color:#ffffff">: You have the right to request that we delete your personal information in
            certain circumstances, such as when it is no longer necessary for the purpose for which it was originally
            collected.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to restriction of processing </span> <span
          style="background-color:#ffffff">: You may also have the right to request to restrict the use of your
            information in certain circumstances, such as when you have objected to our use of your data but we need to
            verify whether we have overriding legitimate grounds to use it.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to data portability </span> <span
          style="background-color:#ffffff">: You have the right to transfer your information to a third party in a
            structured, commonly used and machine-readable format, in circumstances where the information is processed
            with your consent or by automated means.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to object </span> <span
          style="background-color:#ffffff">: You have the right to object to the use of your information in certain
            circumstances, such as the use of your personal information for direct marketing.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#ffffff">Right to complain </span> <span
          style="background-color:#ffffff">: You have the right to complain to the appropriate supervisory authority
            if you have any grievance against the way we collect, use or share your information. This right may not be
            available to you if there is no supervisory authority dealing with data protection in your country.</span>
        </p>
        <h3
          style="margin-top:0pt; margin-bottom:79pt; page-break-inside:auto; page-break-after:auto; line-height:130%; font-size:19.5pt">
          <a name="_8vqpf57d9n4p"></a> <span style="color:#111111; background-color:#ffffff">Retention of
            information</span> </h3>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We retain your personal information for as long as it is required for the
            purposes stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as
            permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in
            connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply
            with other legal obligations. When we no longer have a legitimate need to process your information, we will
            delete or anonymize your information from our active databases. We will also securely store the information
            and isolate it from further processing on backup discs until deletion is possible.</span> </p>
        <h2
          style="margin-top:0pt; margin-bottom:61pt; text-align:center; page-break-inside:auto; page-break-after:auto; line-height:125%; font-size:22.5pt">
          <a name="_a1ngxs8xo820"></a> <span style="background-color:#f7f7f7">Part II – Information that House of Banquets
            processes on your behalf</span> </h2>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#f7f7f7">Information entrusted to House of Banquets and
            purpose</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Information provided in connection with services </span>
          <span style="background-color:#f7f7f7">: You may entrust information that you or your organization (“you”)
            control, to House of Banquets in connection with use of our services or for requesting technical support for
            our products. This includes information regarding your customers and your employees (if you are a
            controller) or data that you hold and use on behalf of another person for a specific purpose, such as a
            customer to whom you provide services (if you are a processor). The data may either be stored on our servers
            when you use our services, or transferred or shared to us as part of a request for technical support or
            other services.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Information from mobile devices </span> <span
          style="background-color:#f7f7f7">: When you elect to allow it, some of our mobile applications have access
            to the camera, microphone, call history, contact information, photo library, files and other information
            stored on your mobile device. Our applications require such access to provide their services. Similarly,
            when you elect to provide access, location-based information is also collected for purposes including, but
            not limited to, locating nearby contacts or setting location-based reminders. This information will be
            exclusively shared with our mapping providers and will be used only for mapping user locations. You may
            disable the mobile applications' access to this information at any time by editing the settings on your
            mobile device. The data stored on your mobile device and their location information to which the mobile
            applications have access will be used in the context of the mobile application, and transferred to and
            associated with your account in the corresponding services (in which case the data will be stored on our
            servers) or products (in which case the data will remain with you unless you share it with us).</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">(All the information entrusted to House of Banquets is collectively termed
            “service data”)</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#f7f7f7">Ownership and control of your service
            data</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">We recognize that you own your service data. We provide you complete
            control of your service data by providing you the ability to (i) access your service data, (ii) share your
            service data through supported third-party integrations, and (iii) request export or deletion of your
            service data.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#f7f7f7">How we use service data</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">We process your service data when you provide us instructions through the
            various modules of our services. For example, when you generate an invoice using our invoicing service,
            information such as the name and address of your customer will be used to generate the invoice; and when you
            use our campaign management service for email marketing, the email addresses of the persons on your mailing
            list will be used for sending the emails.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#f7f7f7">Who we share service data with</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Employees and independent contractors </span> <span
          style="background-color:#f7f7f7">: We may provide access to your service data to our employees and
            individuals who are independent contractors of the House of Banquets web and mobile applications involved in
            providing the services (collectively our “employees”) so that they can (i) identify, analyze and resolve
            errors, (ii) manually verify emails reported as spam to improve spam detection, or (iii) manually verify
            scanned images that you submit to us to verify the accuracy of optical character recognition. We ensure that
            access by our employees to your service data is restricted to specific individuals, and is logged and
            audited. Our employees will also have access to data that you knowingly share with us for technical support
            or to import data into our products or services. We communicate our privacy and security guidelines to our
            employees and strictly enforce privacy safeguards within the House of Banquets group.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Collaborators and other users </span> <span
          style="background-color:#f7f7f7">: Some of our products or services allow you to collaborate with other
            users or third parties. Initiating collaboration may enable other collaborators to view some or all of your
            profile information. For example, when you edit a document that you have shared with other persons for
            collaboration, your name and profile picture will be displayed next to your edits to allow your
            collaborators to know that you made those edits.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Third-party integrations you have enabled </span> <span
          style="background-color:#f7f7f7">: Most of our products and services support integrations with third-party
            products and services. If you choose to enable any third-party integrations, you may be allowing the third
            party to access your service data and personal information about you. We encourage you to review the privacy
            practices of the third-party services and products before you enable integrations with them.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="font-style:italic; background-color:#f7f7f7">Other cases </span> <span
          style="background-color:#f7f7f7">: Other scenarios in which we may share information that are common to
            information covered under Parts I and II are described in Part III.</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="text-decoration:underline; background-color:#f7f7f7">Retention of information</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#f7f7f7">We hold the data in your account as long as you choose to use House of
            Banquets Services. Once you terminate your House of Banquets user account, your data will eventually get
            deleted from the active database during the next clean-up that occurs once in 6 months. The data deleted
            from the active database will be deleted from backups after 3 months.</span> </p>
        <h2
          style="margin-top:0pt; margin-bottom:61pt; text-align:center; page-break-inside:auto; page-break-after:auto; line-height:125%; font-size:22.5pt">
          <a name="_adwj5ro9zijk"></a> <span style="background-color:#ffffff">Part III – General</span> </h2>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_t4u5cbum7nol"></a> <span style="color:#111111; background-color:#ffffff">Children’s personal
            information</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">Our products and services are not directed to individuals under 16. House
            of Banquets does not knowingly collect personal information from children who are under 16 years of age for
            its own purposes. If we become aware that a child under 16 has provided us with personal information, we
            will take steps to delete such information. If you believe that a child under 16 years has provided personal
            information to us, please write to </span> <a href="mailto:info@houseofbanquets.com"
                                                          style="text-decoration:none"> <span
          style="text-decoration:underline; color:#1155cc; background-color:#ffffff">info@houseofbanquets.com</span>
        </a> <span style="background-color:#ffffff"> with the details, and we will take the necessary steps to delete
            the information we hold about that child. However, using our products, you can collect information about
            individuals who may be children. If you process information relating to children, you acknowledge and agree
            that you will be responsible for complying with the applicable laws and regulations related to protection of
            such personal information.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_lg4yztg46tys"></a> <span style="color:#111111; background-color:#ffffff">How secure is your
            information</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">At House of Banquets, we take data security very seriously. We have taken
            steps to implement appropriate administrative, technical &amp; physical safeguards to prevent unauthorized
            access, use, modification, disclosure or destruction of the information you entrust to us. If you have any
            concerns regarding the security of your data, we encourage you to write to us at info@houseofbanquets.com
            with any questions.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_fzyc6n47pfng"></a> <span style="color:#111111; background-color:#ffffff">Locations and international
            transfers</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We share your personal information and service data within the House of
            Banquets Group and with third parties engaged by House of Banquets Group for the purposes described above.
            By accessing or using our products and services or otherwise providing personal information or service data
            to us, you understand that the processing, transfer, and storage of your personal information or Service
            Data to countries where House of Banquets operates. </span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="-aw-import:ignore">&#xa0;</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_ylbyuw27wbl"></a> <span style="color:#111111; background-color:#ffffff">Do Not Track (DNT)
            requests</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">Some internet browsers have enabled 'Do Not Track' (DNT) features, which
            send out a signal (called the DNT signal) to the websites that you visit indicating that you don't wish to
            be tracked. Currently, there is no standard that governs what websites can or should do when they receive
            these signals. For now, we do not take action in response to these signals.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_un7k48gsk3ap"></a> <span style="color:#111111; background-color:#ffffff">External links on our
            websites</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">Some pages of our websites may contain links to websites that are not
            linked to this Privacy Policy. If you submit your personal information to any of these third-party sites,
            your personal information is governed by their privacy policies. As a safety measure, we recommend that you
            not share any personal information with these third parties unless you've checked their privacy policies and
            assured yourself of their privacy practices.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_aiehjnx96vgo"></a> <span style="color:#111111; background-color:#ffffff">Blogs and forums</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We offer publicly accessible blogs and forums on our websites. Please be
            aware that any information you provide on these blogs and forums may be used to contact you with unsolicited
            messages. We urge you to be cautious in disclosing personal information in our blogs and forums. House of
            Banquets is not responsible for the personal information you elect to disclose publicly. Your posts and
            certain profile information may remain even after you terminate your account with House of Banquets. To
            request the removal of your information from our blogs and forums, you can contact us at
            info@houseofbanquets.com</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:19.5pt"> <span
          style="color:#111111; background-color:#ffffff">Social media widgets</span> </p>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">Our websites include social media widgets such as Facebook "like" buttons
            and Twitter "tweet" buttons that let you share articles and other information. These widgets may collect
            information such as your IP address and the pages you navigate in the website, and may set a cookie to
            enable the widgets to function properly. Your interactions with these widgets are governed by the privacy
            policies of the companies providing them.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_vxmg1krmnwta"></a> <span style="color:#111111; background-color:#ffffff">Disclosures in compliance
            with legal obligations</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We may be required by law to preserve or disclose your personal information
            and service data to comply with any applicable law, regulation, legal process or governmental request,
            including to meet national security requirements.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_1zrxjmou81nr"></a> <span style="color:#111111; background-color:#ffffff">Enforcement of our
            rights</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We may disclose personal information and service data to a third party if
            we believe that such disclosure is necessary for preventing fraud, spam filtering, investigating any
            suspected illegal activity, enforcing our agreements or policies, or protecting the safety of our
            users.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_dcxgf4tbintq"></a> <span style="color:#111111; background-color:#ffffff">Business Transfers</span>
        </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We do not intend to sell our business. However, in the unlikely event that
            we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to
            honor our commitments to you. We will notify you via email or through a prominent notice on our website of
            any change in ownership or in the uses of your personal information and service data. We will also notify
            you about any choices you may have regarding your personal information and service data.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_7fd8ajko7uy4"></a> <span style="color:#111111; background-color:#ffffff">Compliance with this Privacy
            Policy</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We make every effort, including periodic reviews, to ensure that personal
            information you provide is used in conformity with this Privacy Policy. If you have any concerns about our
            adherence to this Privacy Policy or the manner in which your personal information is used, kindly write to
            us </span> <a href="mailto:info@houseofbanquets.com" style="text-decoration:none"> <span
          style="text-decoration:underline; color:#1155cc; background-color:#ffffff">info@houseofbanquets.com</span>
        </a> <span style="background-color:#ffffff; -aw-import:spaces">&#xa0; </span> <span
          style="background-color:#ffffff">We'll contact you, and if required, coordinate with the appropriate
            regulatory authorities to effectively address your concerns.</span> </p>
        <h4
          style="margin-top:0pt; margin-bottom:76pt; page-break-inside:auto; page-break-after:auto; line-height:140%; font-size:19.5pt">
          <a name="_rr57f9fu0c26"></a> <span style="color:#111111; background-color:#ffffff">Notification of
            changes</span> </h4>
        <p class="normal0" style="margin-bottom:83pt; line-height:160%; font-size:13.5pt"> <span
          style="background-color:#ffffff">We may modify the Privacy Policy at any time, upon notifying you through a
            service announcement or by sending an email to your primary email address. If we make significant changes to
            the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of
            the changes by email to your primary email address. However, if you have not verified your email address,
            you may miss important notifications that we send through email. If you think that the updated Privacy
            Policy affects your rights with respect to your use of our products or services, you may terminate your use
            by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy
            Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email
            notification of minor changes to the Privacy Policy. If you are concerned about how your personal
            information is used, you should check back at https://houseofbanquets.com/admin/privacy-policy
            periodically.</span> </p>
        <p class="normal0"> <span style="-aw-import:ignore">&#xa0;</span> </p>
      </div>
    </b-container>
</template>

<script>

    export default {
        data() {
            return {}
        },
    }
</script>

<style scoped>
body {
  line-height: 115%;
  font-family: Arial;
  font-size: 11pt
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0pt
}

li {
  margin-top: 0pt;
  margin-bottom: 0pt
}

h1 {
  margin-top: 20pt;
  margin-bottom: 6pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 20pt;
  font-weight: normal;
  color: #000000
}

h2 {
  margin-top: 18pt;
  margin-bottom: 6pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 16pt;
  font-weight: normal;
  color: #000000
}

h3 {
  margin-top: 16pt;
  margin-bottom: 4pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 14pt;
  font-weight: normal;
  color: #434343
}

h4 {
  margin-top: 14pt;
  margin-bottom: 4pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 12pt;
  font-weight: normal;
  font-style: normal;
  color: #666666
}

h5 {
  margin-top: 12pt;
  margin-bottom: 4pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 11pt;
  font-weight: normal;
  color: #666666
}

h6 {
  margin-top: 12pt;
  margin-bottom: 4pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 11pt;
  font-weight: normal;
  font-style: italic;
  color: #666666
}

.Subtitle {
  margin-top: 0pt;
  margin-bottom: 16pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-family: Arial;
  font-size: 15pt;
  font-style: normal;
  color: #666666
}

.Title {
  margin-top: 0pt;
  margin-bottom: 3pt;
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: avoid;
  line-height: 115%;
  font-size: 26pt
}

.normal0 {
  line-height: 115%;
  font-size: 11pt
}

.TableNormal {}
</style>
